@import './colors';

.container-aspect-ratio {
    position: relative;
    width: 100%;

    &.four-three {
        padding-bottom: calc(100% / (4 / 3));
    }

    &.three-four {
        padding-bottom: calc(100% / (3 / 4));
    }

    &.square {
        padding-bottom: 100%;
    }

    & > div {
        position: absolute;
        inset: 0;
    }
}

.base-snackbar {
    .MuiSnackbarContent-root {
        background-color: white !important;
        border-radius: 10px !important;
        border: 1px solid $light-gray-focus;
        color: $black !important;

        .MuiSnackbarContent-message {
            margin: 0 auto;

            a,
            a:visited,
            a:hover {
                color: $blue !important;
            }
        }

        .MuiSnackbarContent-action {
            margin: 0 auto;

            button:not(:last-child) {
                margin-right: 1rem;
            }
        }
    }
}
