@import './components/SideColumnsContent/CardContainer/CardContainer';
@import './components/Layout/BaseDialog/BaseDialog';
@import './styles/colors';
@import './styles/utils';

$site-bg: $light-gray;

* {
    font-family: 'Open Sans', 'Montserrat', Arial;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: none;
}

body {
    background-color: $site-bg;

    .MuiButton-root {
        border-radius: 8px;
    }
}
