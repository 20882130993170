$total-page-max-width: 1300px;

// Header
$header-height: 2.4rem;
$header-with-menu-height: 3.55rem;

// Column(s)
$side-columns-width: 13rem;
$column-padding: 0.5rem;

// ### Media query breakpoints (devices)

// Default (mobile portrait). No media query
// Mobile landscape
$breakpoint-4: 480px;
$breakpoint-mobile-landscape: $breakpoint-4;

// Tablet portrait
$breakpoint-7: 768px;
$breakpoint-tablet-portrait: $breakpoint-7;

// Tablet landscape
$breakpoint-9: 992px;
$breakpoint-tablet-landscape: $breakpoint-9;

// Desktop
$breakpoint-12: 1200px;
$breakpoint-desktop: $breakpoint-12;
