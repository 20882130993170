@import '../../../../styles/layout';

#account-menu {
    .MuiPaper-root {
        margin-top: -2.55rem;

        @media (min-width: $breakpoint-7) {
            margin-top: 0;
        }
    }
}
