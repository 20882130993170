@import '../../../styles/colors';

div.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #0f0f0f;
    opacity: 0.2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 9000;

    .MuiCircularProgress-colorPrimary {
        color: $white;
    }
}
