@import '../../../styles/colors';

ul.corporate-links {
    padding-top: 0.5rem;

    li {
        list-style: none;
        text-align: center;
        padding-bottom: 0.5rem;

        a {
            color: $dark-gray;
            text-decoration: none;
            white-space: nowrap;

            &:hover {
                text-decoration: underline;
            }
        }

        span.copyright {
            display: block;
            margin-top: 0.3rem;
            font-size: 1.1rem;
            font-weight: bold;
        }
    }
}
