@import '../../styles/layout';

#columns-container {
    margin: auto;
    max-width: $total-page-max-width;

    #center-column {
        @import '../forms/forms';

        border-bottom: $header-with-menu-height solid transparent;
        padding: $header-height + $column-padding $column-padding
            $column-padding;

        // Hides the center column scrollbar on IE and Edge
        -ms-overflow-style: none;
        // Hides the center column scrollbar on Firefox
        scrollbar-width: none;
        // Hides the center column scrollbar on Chrome, Safari and Opera
        &::-webkit-scrollbar {
            display: none;
        }

        @media (min-width: $breakpoint-7) {
            padding: $column-padding;
            border-top: $header-with-menu-height solid transparent;
            border-bottom: 0;
        }

        @media (min-width: $breakpoint-9) {
            overflow-y: auto;
        }
    }
}
