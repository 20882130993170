.corporate-page {
    padding: 1rem;

    h1 {
        font-size: 1.4rem;
        line-height: 2.6rem;
        padding-bottom: 0.2rem;
    }

    p {
        line-height: 120%;
        margin-bottom: 1rem;
    }
}
