@import '../../../styles/colors';
@import '../../../styles/layout';

#header {
    transition: transform 1s cubic-bezier(0, 0, 0.2, 1) 1s !important;

    #header-container {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        width: 100%;
        height: $header-height;
        background-color: white;
        z-index: 1;
        color: $dark-gray;
        border-bottom: 1px solid $light-gray-focus;
        padding: 0 calc(
            (100% - $total-page-max-width) / 2
        );

        #logo-header {
            color: inherit;
            font-weight: bold;
            padding-top: 0.5rem;
            padding-left: 0.5rem;

            #logo {
                width: 6rem;
            }
        }

        #navigation-options {
            margin-left: auto;
        }
    }

    @media (min-width: $breakpoint-7) {
        #header-container {
            height: 3.55rem;

            #logo-header {
                padding-top: 1rem;
                padding-left: 1rem;

                #logo {
                    width: auto;
                }
            }
        }
    }
}
