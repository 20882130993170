div.not-found-container {
    width: 100%;
    text-align: center;

    h1 {
        font-size: 2rem;
        margin: 1rem 0 2rem;
    }

    p {
        font-size: 1.1rem;
        margin: 0 0.2rem 2rem;
    }
}
