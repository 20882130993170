// Import parent forms.scss into components instead of importing this styles file
@import '../../../styles/colors';

.MuiButton-root {
    color: white;
    box-shadow: none;
    background-color: $dark-primary;

    &:hover {
        background-color: $primary;
    }

    &.MuiButton-containedSecondary {
        background-color: $dark-secondary;

        &:hover {
            background-color: $secondary;
        }
    }

    &.Mui-disabled {
        background-color: $gray;
    }
}
