// This SCSS SHOULD NOT be included in its component owner to avoid multiple-inclusions
@import '../../../styles/colors';

.MuiPaper-root.card-container {
    background-color: white;
    border-radius: 10px;
    margin: 0 !important;
    width: 100%;
    height: fit-content;
    border: 1px solid $light-gray-focus;

    .MuiPaper-root.card-header {
        border-radius: 10px 10px 0 0;
        margin: 0 !important;
        width: 100%;
        height: 2rem;
        padding: 0.5rem 0.5rem 0;
        background-color: $orange;
        color: white;
        font-weight: bold;
        text-align: center;
    }

    .card-content {
        padding: 0.5rem;
    }
}
