@import '../../../styles/colors';

#navigation {
    .MuiButtonBase-root {
        color: $dark-gray;

        &[color='disabled'] {
            color: $light-gray-plus;
        }
    }

    .Mui-selected {
        color: $primary;
    }
}
