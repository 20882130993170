@import '../../../styles/layout';

#left-column {
    display: none;
    border-top: $header-with-menu-height solid transparent;
    border-bottom: 0;

    @media (min-width: $breakpoint-9) {
        display: block;
        width: $side-columns-width;
        padding: $column-padding;
    }
}
