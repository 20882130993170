@import '../../../styles/layout';

#right-column {
    display: none;
    border-top: $header-with-menu-height solid transparent;
    border-bottom: 0;

    @media (min-width: $breakpoint-12) {
        display: block;
        width: $side-columns-width;
        padding: $column-padding;
    }
}
