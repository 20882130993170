@import '../../../styles/colors';

#navigation-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid $light-gray-focus;
    background-color: white;

    // Overwriting .MuiPaper-root
    margin: 0 !important;
}
